import NeoDialog from "../../NeoDialog";
class Facility {
  constructor() { }

  start() {
    if ($('body.facilities_show, body.facilities_standalone').length < 1) {
      return
    }

    $('#create_order_button').on('click', (event) => {
      event.preventDefault();
      const userType = event.target.dataset.userType;
      const primaryName = event.target.dataset.primaryName;
      const primaryEmail = event.target.dataset.primaryEmail;

      if (userType == 'primary') {
        const $this = $(event.currentTarget);

        const event_date = $('#booking_date').val(),
          facility_id = $('#facility_id').val();
  
        const host = window.location.host,
          protocol = window.location.protocol,
          // TODO: Use the URL and URLSearchParams API
          url = protocol + '//' + host + $this.attr('href') + '?event_date=' + event_date + '&facility_id=' + facility_id;
  
        return window.location = url;
      } else {
        const message = "This account is a secondary account linked to " + primaryName + " - " + primaryEmail + 
                        ". It is not possible to make bookings or send messages as a secondary account.";
        NeoDialog.confirm(message, 'Ok', null, false)
      }
    });

    if ($('input#is_new_message').val() === 'new') {
      $('a#new_message').click();
    }
  }
}

export default Facility;