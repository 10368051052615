import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import NeoModal from  "../src/NeoModal";

class ManageSecondaryUserController extends Controller {
  connect() {
    this.element.addEventListener('click', (event) => {
      event.preventDefault();

      axios.get(this.element.href).then((response) => {
        const modal = NeoModal.add(response.data);

        modal.addEventListener('neo_modal:success', (event) => {
          $(event.target).modal('hide');
          location.reload();
        })
      })
    });
  }
}

export default ManageSecondaryUserController;
